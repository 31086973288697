#map {
  width: 100% !important;
}
#searchbox {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  padding-left: 21px;
  height: 56px;
  border-radius: 4px;
  border: none;
  font-size: 20px;
  color: rgb(134, 142, 150);
}
.search {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}

.search input {
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
}

.locate {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 30px;
  cursor: pointer;
}

.error {
  color: red;
}

.when-and-where-event-logo-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.5);
}
.when-and-where-event-btn {
  margin-top: 20%;
  text-align: center;
}

.when-and-where-people-btn {
  text-align: center;
}

.when-and-where-event-details-title {
  text-align: center;
  font-size: 36px;
  color: rgba(0, 0, 0, 0.5);
}

.when-and-where-event-details-content {
  background-color: rgba(43, 187, 211, 0.25);
  margin-right: 30px;
  margin-top: 2%;
  padding: 2%;
}

.when-and-where-event-text-input input {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  height: 56px;
  border-radius: 4px;
  border: none;
}

.event-name-text-input input {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  height: 56px;
  border-radius: 4px;
  border: none;
}
#event-name-input{
  padding-left: 21px;
  color: rgb(134, 142, 150);
  font-size: 20px;
}

.event-fee-number-input input {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 1%;
  height: 56px;
  border-radius: 4px;
  border: none;
}

.when-and-where-event-date-input input {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  height: 56px;
  border-radius: 4px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  color: rgb(134, 142, 150);
}

.when-and-where-event-time-input input {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  height: 56px;
  border-radius: 4px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  color: rgb(134, 142, 150);
}

.event-name-text-input input::placeholder {
  font-size: 20px;
  color: rgb(134, 142, 150);
}

.event-fee-number-input input::placeholder {
  font-size: 20px;
  padding-left: 20px;
  color: rgb(134, 142, 150);
}
.event-fee-number-input input {
  width: 80%;
}
span.create-event-fee-sign {
  font-size: 30px;
  position: relative;
  left: 10px;
  top: 5px;
}
.when-and-where-event-text-input input::placeholder {
  font-size: 20px;
  padding-left: 20px;
  color: rgb(134, 142, 150);
}

.when-and-where-next-btn {
  text-align: right;
  margin-top: 2%;
}

.when-and-where-next-btn input {
  font-size: 18px;
  background-color: rgb(228, 49, 147);
  font-weight: bold;
  color: white;
  border: none;
  padding: 10px 15px 10px 15px;
  border-radius: 4px;
}
.when-and-where-custom-dropdown {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 1%;
  height: 56px;
  border-radius: 4px;
  border: none;
  background-color: white;
}

button#dropdown-basic {
  height: 56px;
  box-shadow: none;
  width: 100%;
  text-align: left;
  font-size: 18px;
  color: rgb(134, 142, 150);
}

@media only screen and (min-width: 375px) and (max-width: 736px) {
  .when-and-where-event-cml-logo {
    text-align: center;
  }

  .when-and-where-event-details-content {
    background-color: rgba(43, 187, 211, 0.25);
    margin-right: 0px;
    margin-top: 2%;
    padding: 2%;
  }

  .when-and-where-custom-dropdown {
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
    height: 56px;
    border-radius: 4px;
    border: none;
    background-color: white;
  }

  .event-fee-number-input input {
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
    height: 56px;
    border-radius: 4px;
    border: none;
  }
}

@media only screen and (min-width: 737px) and (max-width: 992px) {
  .when-and-where-event-details-content {
    background-color: rgba(43, 187, 211, 0.25);
    margin-right: 0px;
    margin-top: 4%;
    padding: 2%;
  }

  .when-and-where-event-btn {
    margin-top: 20%;
    text-align: center;
  }

  .when-and-where-people-btn {
    text-align: center;
  }

}
