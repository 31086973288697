#disableButton{
  background-color: gray;
}
.error {
  color: red;
}
input#male {
  margin-right: 10px;
}
input#female {
  margin-right: 10px;
}
input#other {
  margin-right: 10px;
}
.modalConent{
  text-align: center;
}
button.modalButton.btn.btn-primary:hover {
  color: white;
  background-color:rgb(228, 49, 147);
  border-color: rgb(228, 49, 147);
}
.messageModal{
  margin-top: 300px;
}
.modalButton{
  padding: 4px 40px 4px 40px;
  background-color: rgb(228, 49, 147);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
}
.who-is-invited-event-logo-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.who-is-invited-event-btn {
  margin-top: 20%;
  text-align: center;
}

.who-is-invited-people-btn {
  text-align: center;
}

.who-is-invited-event-details-title {
  text-align: center;
  font-size: 36px;
  color: rgba(0, 0, 0, 0.5);
}

.who-is-invited-event-details-content {
  background-color: rgba(43, 187, 211, 0.25);
  margin-right: 30px;
  margin-top: 2%;
  padding: 2%;
}

.who-is-invited-event-text-input input {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  height: 56px;
  border-radius: 4px;
  border: none;
}
.who-is-invited-event-number-input input {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  height: 56px;
  border-radius: 4px;
  border: none;
}
.who-is-invited-event-text-input select {
  width: 100%;
  margin-top: 0%;
  margin-bottom: 0%;
  height: 56px;
  border-radius: 4px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  color: rgb(134, 142, 150);
}
.who-is-invited-event-text-input input::placeholder {
  font-size: 20px;
  padding-left: 20px;
  color: rgb(134, 142, 150);
}
.who-is-invited-event-number-input input::placeholder {
  font-size: 20px;
  color: rgb(134, 142, 150);
  padding-left: 20px;
}
.women-who-is-invited-select-button {
  margin: 5px 5px 5px 0px;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  background-color: #ffdcef;
}
.selected-events{
  box-shadow: 3px 3px 8px 0 #000;
}
.caret-right:hover {
    color: rgb(43, 187, 211);
}

.men-who-is-invited-select-button {
  margin: 5px 5px 5px 0px;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  background-color: rgba(43, 187, 211, 0.25);
}
.who-is-invited-age {
  padding: 8px 20px 8px 20px;
  margin-right: 2px;
}

.who-is-invited-status {
  padding: 8px 20px 8px 20px;
  margin-right: 2px;
}

.who-is-invited-events {
  padding: 8px 20px 8px 20px;
  margin-right: 2px;
}
.who-is-invited-lbl label {
  font-size: 18px;
  margin-top: 2%;
}
.who-is-invited-personal-info {
  width: 100%;
  text-align: right;
  margin-top: 3%;
}
.left-who-is-invited-results {
  background-color: white;
  padding: 2px 7px 25px 7px;
  height: 600px;
  overflow-y: scroll;
}
.right-who-is-invited-results {
  background-color: white;
  padding: 2px 7px 215px 7px;
  height: 600px;
  overflow-y: scroll;
}
.who-is-invited-back-btn {
  text-align: left;
  margin-top: 7%;
}

.who-is-invited-back-btn input {
  font-size: 18px;
  background-color: rgb(228, 49, 147);
  font-weight: bold;
  color: white;
  border: none;
  padding: 10px 15px 10px 15px;
  border-radius: 4px;
}
.who-is-invited-next-btn {
  text-align: right;
  margin-top: 7%;
}

.who-is-invited-next-btn input {
  font-size: 18px;
  background-color: rgb(228, 49, 147);
  font-weight: bold;
  color: white;
  border: none;
  padding: 10px 15px 10px 15px;
  border-radius: 4px;
}
.caret-right {
  text-align: center;
  font-size: 151px;
  margin-top: 126%;
  color: rgb(228, 49, 147);
}

@media only screen and (min-width: 375px) and (max-width: 736px) {
  .who-is-invited-event-cml-logo {
    text-align: center;
  }
  .who-is-invited-event-details-content {
    background-color: rgba(43, 187, 211, 0.25);
    margin-right: 0px;
    margin-top: 2%;
    padding: 2%;
  }
  .who-is-invited-event-number-input input {
    width: 100%;
    margin-top: 1%;
    margin-bottom: 6%;
    height: 56px;
    border-radius: 4px;
    border: none;
  }
  .caret-right {
    text-align: center;
    font-size: 151px;
    margin-top: 0%;
    color: rgb(228, 49, 147);
  }
  .who-is-invited-personal-info {
    width: 100%;
    text-align: left;
    margin-top: 1%;
  }
}
@media only screen and (min-width: 737px) and (max-width: 992px) {
  .who-is-invited-event-details-content {
    background-color: rgba(43, 187, 211, 0.25);
    margin-right: 0px;
    margin-top: 4%;
    padding: 2%;
  }

  .who-is-invited-event-btn {
    margin-top: 20%;
    text-align: center;
  }

  .who-is-invited-people-btn {
    text-align: center;
  }

  .who-is-invited-back-btn {
    text-align: left;
    margin-top: 7%;
  }

  .who-is-invited-back-btn input {
    font-size: 13px;
    background-color: rgb(228, 49, 147);
    font-weight: bold;
    color: white;
    border: none;
    padding: 7px 10px 7px 10px;
    border-radius: 4px;
  }
  .who-is-invited-next-btn {
    text-align: right;
    margin-top: 7%;
  }

  .who-is-invited-next-btn input {
    font-size: 13px;
    background-color: rgb(228, 49, 147);
    font-weight: bold;
    color: white;
    border: none;
    padding: 7px 10px 7px 10px;
    border-radius: 4px;
  }
  .caret-right {
    text-align: center;
    font-size: 151px;
    margin-top: 450%;
    color: rgb(228, 49, 147);
  }
  .who-is-invited-event-text-input select {
    width: 100%;
    margin-top: 0%;
    margin-bottom: 0%;
    height: 56px;
    border-radius: 4px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    color: rgb(134, 142, 150);
  }
  .who-is-invited-event-text-input input::placeholder {
    font-size: 14px;
    padding-left: 20px;
    color: rgb(134, 142, 150);
  }
  .who-is-invited-event-number-input input::placeholder {
    font-size: 14px;
    padding-left: 20px;
    color: rgb(134, 142, 150);
  }
}
.invited-user{
  padding: 30px;
}
