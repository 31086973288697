body {
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-family: sans-serif;
}
.submit-btn button {
  padding: 7px 40px 7px 40px;
  background-color: rgb(43, 187, 211);
  color: white;
  border: none;
  border-radius: 4px;
  margin-bottom: 15px;
}
.people-listing-scroll-bar {
  height: 550px;
  padding-left: 20px;
  padding-top: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.people-listing-heading-style {
  padding-left: 20px;
}
.no-user-found {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.results-with-pictures-logo-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.results-with-pictures-event-btn {
  margin-top: 20%;
  text-align: center;
}

.results-with-pictures-people-btn {
  text-align: center;
}

.results-with-pictures-details-title {
  text-align: center;
  font-size: 36px;
  color: rgba(0, 0, 0, 0.5);
}

.results-with-pictures-details-content {
  background-color: rgba(43, 187, 211, 0.25);
  margin-right: 30px;
  margin-top: 4%;
  padding: 2%;
}

.results-with-pictures-text-input input {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 16px;
  height: 56px;
  border-radius: 4px;
  padding-left: 21px;
  font-size: 20px;
  color: rgb(134, 142, 150);
  border: none;
}

.results-with-pictures-number-input input {
  width: 100%;
  margin-top: 0%;
  margin-bottom: 0%;
  height: 56px;
  border-radius: 4px;
  padding-left: 21px;
  font-size: 20px;
  border: none;
  color: rgb(134, 142, 150);
}

.results-with-pictures-text-input select {
  width: 100%;
  margin-top: 0%;
  margin-bottom: 0%;
  height: 56px;
  border-radius: 4px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  color: rgb(134, 142, 150);
}

.results-with-pictures-text-input input::placeholder {
  font-size: 20px;
  color: rgb(134, 142, 150);
}

.results-with-pictures-number-input input::placeholder {
  font-size: 20px;
  color: rgb(134, 142, 150);
}

.people-results-badges-info {
  text-align: right;
  position: relative;
  top: -12px;
}

span.people-result-badge-events {
  padding: 11px 24px 11px 25px;
  position: relative;
  top: 8px;
  left: 10px;
}
span.people-result-badge-age {
  padding: 11px 24px 11px 25px;
  position: relative;
  top: 8px;
}

span.people-result-badge-status {
  padding: 11px 24px 11px 25px;
  position: relative;
  top: 8px;
  left: 10px;
}

.people-result-pic img {
  width: 87px;
  height: 87px;
  border-radius: 50%;
  border: 5px solid rgb(228, 49, 147);
  object-fit: fill;
}

.people-result-pic {
  position: relative;
  right: 30px;
  bottom: 16px;
}

.people-results-info {
  margin-right: 30px;
  margin-top: 2%;
}

.people-reult-name {
  position: relative;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  top: 10px;
}
.people-listings {
  margin-right: 3%;
}
.women-results {
  background-color: rgba(242, 132, 192, 0.25);
  width: 100%;
  margin: auto;
  margin-bottom: 5%;
  height: 50px;
}

.men-results {
  background-color: rgba(43, 187, 211, 0.25);
  margin: auto;
  margin-bottom: 5%;
  height: 50px;
}

.people-results-headings-row {
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 21px;
  color: rgba(0, 0, 0, 0.5);
}

.people-results-row {
  margin-right: 30px;
  padding: 2% 0 2% 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 24px;
}

.people-catogories {
  text-align: right;
}

.people-results-name-heading {
  font-size: 21px;
  color: rgba(0, 0, 0, 0.5);
}

.people-result-men-pic img {
  width: 87px;
  height: 87px;
  border-radius: 50%;
  border: 5px solid rgb(43, 187, 211);
  object-fit: fill;
}

.people-result-men-pic {
  position: relative;
  right: 30px;
  bottom: 16px;
}

@media only screen and (min-width: 360px) and (max-width: 374px) {
 
  .results-with-pictures-details-content {
    background-color: rgba(43, 187, 211, 0.25);
    margin-right: 0px;
    margin-top: 2%;
    padding: 2%;
  }

  .results-with-pictures-number-input input {
    width: 100%;
    margin-top: 1%;
    margin-bottom: 6%;
    height: 56px;
    border-radius: 4px;
    border: none;
  }
  .people-listing-heading-style {
    padding-left: 20px;
    position: relative;
    top: 50px;
  }

  .people-results-row {
    margin-right: 0px;
    padding: 2% 0 2% 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 24px;
  }

  span.people-result-badge-age {
    padding: 11px 24px 11px 25px;
    position: relative;
    left: -33px;
  }

  span.people-result-badge-status {
    padding: 11px 24px 11px 25px;
    position: relative;
    top: -26px;
    left: 81px;
  }

  span.people-result-badge-events {
    padding: 11px 24px 11px 25px;
    position: relative;
    top: -60px;
    left: 181px;
  }
  .women-results {
    background-color: rgba(242, 132, 192, 0.25);
    width: 95%;
    margin: auto;
    height: 80px;
    margin-top: 137px;
    margin-left: 5%;
    margin-bottom: 5%;
  }

  .men-results {
    background-color: rgba(43, 187, 211, 0.25);
    width: 95%;
    margin: auto;
    height: 80px;
    margin-top: 137px;
    margin-left: 5%;
    margin-bottom: 5%;
  }

  .people-result-pic img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 7px solid rgb(228, 49, 147);
    object-fit: fill;
  }
  .people-result-men-pic img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 5px solid rgb(43, 187, 211);
    object-fit: fill;
  }

  .people-reult-name {
    position: relative;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    top: -65px;
    left: 90px;
  }

  .people-results-badges-info {
    text-align: left;
    position: relative;
    top: -62px;
    margin-left: 67px;
  }

  span.people-results-events-heading {
    position: relative;
    top: -61px;
    left: 220px;
  }
  .people-results-status-heading {
    position: relative;
    top: -31px;
    left: 107px;
  }

  .people-results-name-heading {
    text-align: center;
  }

  .people-results {
    text-align: center;
  }

  .people-catogories {
    text-align: center;
  }

  .people-result-pic {
    position: relative;
    right: 36px;
    top: -7px;
  }

  .people-result-men-pic {
    position: relative;
    right: 36px;
    top: -7px;
  }
  .logout-btn {
    text-align: center;
    padding-top: 10px;
    padding-right: 10px;
}
.event-listing-cml-logo {
  text-align: center;
}
}

@media only screen and (min-width: 375px) and (max-width: 414px) {
  .results-with-pictures-cml-logo {
    text-align: center;
  }
  .people-results-info-headings {
    text-align: left;
  }
  .results-with-pictures-details-content {
    background-color: rgba(43, 187, 211, 0.25);
    margin-right: 0px;
    margin-top: 2%;
    padding: 2%;
  }

  .results-with-pictures-number-input input {
    width: 100%;
    margin-top: 1%;
    margin-bottom: 6%;
    height: 56px;
    border-radius: 4px;
    border: none;
  }
  .people-listing-heading-style {
    padding-left: 20px;
    position: relative;
    top: 50px;
  }

  .people-results-row {
    margin-right: 0px;
    padding: 2% 0 2% 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 24px;
  }

  span.people-result-badge-age {
    padding: 11px 24px 11px 25px;
    position: relative;
    left: -33px;
  }

  span.people-result-badge-status {
    padding: 11px 24px 11px 25px;
    position: relative;
    top: -26px;
    left: 81px;
  }

  span.people-result-badge-events {
    padding: 11px 24px 11px 25px;
    position: relative;
    top: -60px;
    left: 199px;
  }
  .women-results {
    background-color: rgba(242, 132, 192, 0.25);
    width: 95%;
    margin: auto;
    height: 80px;
    margin-top: 137px;
    margin-left: 5%;
    margin-bottom: 5%;
  }

  .men-results {
    background-color: rgba(43, 187, 211, 0.25);
    width: 95%;
    margin: auto;
    height: 80px;
    margin-top: 137px;
    margin-left: 5%;
    margin-bottom: 5%;
  }

  .people-result-pic img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 7px solid rgb(228, 49, 147);
    object-fit: fill;
  }
  .people-result-men-pic img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 5px solid rgb(43, 187, 211);
    object-fit: fill;
  }

  .people-reult-name {
    position: relative;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    top: -65px;
    left: 90px;
  }

  .people-results-badges-info {
    text-align: left;
    position: relative;
    top: -62px;
    margin-left: 67px;
  }

  span.people-results-events-heading {
    position: relative;
    top: -61px;
    left: 220px;
  }
  .people-results-status-heading {
    position: relative;
    top: -31px;
    left: 100px;
  }

  .people-results-name-heading {
    text-align: center;
  }

  .people-results {
    text-align: center;
  }

  .people-catogories {
    text-align: center;
  }

  .people-result-pic {
    position: relative;
    right: 36px;
    top: -7px;
  }

  .people-result-men-pic {
    position: relative;
    right: 36px;
    top: -7px;
  }
}

@media only screen and (min-width: 415px) and (max-width: 539px) {
  .people-result-pic img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 7px solid rgb(228, 49, 147);
    object-fit: fill;
  }
  .people-result-men-pic img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 5px solid rgb(43, 187, 211);
    object-fit: fill;
  }

  .people-reult-name {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    position: relative;
    top: -80px;
    left: 125px;
}

  .people-results-badges-info {
    text-align: left;
    position: relative;
    top: -61px;
    margin-left: 70px;
  }
  .men-results {
    background-color: rgba(43, 187, 211, 0.25);
    margin: auto;
    margin-bottom: 34%;
    height: 50px;
}
span.people-result-badge-age {
  padding: 11px 24px 11px 25px;
  position: relative;
  top: -27px;
}
span.people-result-badge-status {
  padding: 11px 24px 11px 25px;
  position: relative;
  top: -61px;
  left: 114px;
}
span.people-result-badge-events {
  padding: 11px 24px 11px 25px;
  position: relative;
  top: -95px;
  left: 243px;
}
.people-results-name-heading {
  font-size: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}
span.people-results-age-heading {
  position: relative;
  top: 31px;
  left: 30px;
}
span.people-results-status-heading {
  position: relative;
  left: 135px;
}
span.people-results-events-heading {
  position: relative;
  top: -30px;
  left: 263px;
}
}
@media only screen and (min-width: 540px) and (max-width: 736px) {
  .people-result-pic img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 7px solid rgb(228, 49, 147);
    object-fit: fill;
  }
  .people-result-men-pic img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 5px solid rgb(43, 187, 211);
    object-fit: fill;
  }

  .people-reult-name {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    position: relative;
    top: -80px;
    left: 154px;
}

  .people-results-badges-info {
    text-align: left;
    position: relative;
    top: -61px;
    margin-left: 70px;
  }
  .men-results {
    background-color: rgba(43, 187, 211, 0.25);
    margin: auto;
    margin-bottom: 34%;
    height: 67px;
}
span.people-result-badge-age {
  padding: 11px 24px 11px 25px;
  position: relative;
  top: -27px;
}
span.people-result-badge-status {
  padding: 11px 24px 11px 25px;
  position: relative;
  top: -61px;
  left: 180px;
}
span.people-result-badge-events {
  padding: 11px 24px 11px 25px;
  position: relative;
  top: -95px;
  left: 365px;
}
.people-results-name-heading {
  font-size: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}
span.people-results-age-heading {
  position: relative;
  top: 31px;
  left: 30px;
}
span.people-results-status-heading {
  position: relative;
  left: 193px;
}
span.people-results-events-heading {
  position: relative;
  top: -30px;
  left: 380px;
}
}

@media only screen and (min-width: 737px) and (max-width: 992px) {
  .results-with-pictures-details-content {
    background-color: rgba(43, 187, 211, 0.25);
    margin-right: 0px;
    margin-top: 4%;
    padding: 2%;
  }

  .results-with-pictures-event-btn {
    margin-top: 20%;
    text-align: center;
  }

  .results-with-pictures-people-btn {
    text-align: center;
  }

  .results-with-pictures-text-input select {
    width: 100%;
    margin-top: 0%;
    margin-bottom: 0%;
    height: 56px;
    border-radius: 4px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    color: rgb(134, 142, 150);
  }

  .results-with-pictures-text-input input::placeholder {
    font-size: 14px;
    padding-left: 20px;
    color: rgb(134, 142, 150);
  }

  .results-with-pictures-number-input input::placeholder {
    font-size: 14px;
    padding-left: 20px;
    color: rgb(134, 142, 150);
  }

  .event-listing-cml-logo {
    text-align: left;
  }

  .results-with-pictures-number-input input {
    width: 100%;
    margin-top: 1%;
    margin-bottom: 6%;
    height: 56px;
    border-radius: 4px;
    border: none;
  }

  span.people-result-badge-age {
    padding: 11px 24px 11px 25px;
    margin-left: 10px;
    margin-top: 1px;
    margin-right: 4%;
  }

  span.people-result-badge-status {
    padding: 11px 24px 11px 25px;
  }

  span.people-result-badge-events {
    padding: 11px 24px 11px 25px;
  }

  .women-results {
    background-color: rgba(242, 132, 192, 0.25);
    width: 95%;
    margin: auto;
    height: 65px;
    margin-bottom: 6%;
  }

  .men-results {
    background-color: rgba(43, 187, 211, 0.25);
    width: 95%;
    margin: auto;
    height: 65px;
    margin-bottom: 6%;
  }

  .people-result-pic img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 7px solid rgb(228, 49, 147);
    object-fit: fill;
  }
  .people-result-men-pic img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 5px solid rgb(43, 187, 211);
    object-fit: fill;
  }

  .people-reult-name {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
  }

  .people-results-badges-info {
    text-align: right;
    position: relative;
    top: -80px;
    margin-left: 56%;
  }

  .people-results-age-heading {
    position: relative;
    left: -58px;
  }

  span.people-results-events-heading {
    position: relative;
    top: -28px;
    left: 98px;
  }
  .people-results-status-heading {
    position: relative;
    top: -31px;
    left: 297px;
  }

  .people-results-name-heading {
    position: relative;
    left: 20px;
  }

  .people-results {
    text-align: left;
  }

  .people-catogories {
    text-align: right;
  }

  .people-result-pic {
    position: relative;
    right: 28px;
  }

  .people-result-men-pic {
    position: relative;
    right: 28px;
  }
  .people-results-row {
    margin-right: 0px;
    padding: 2% 0 2% 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .event-listing-cml-logo {
    text-align: left;
  }

  .people-results-badges-info {
    text-align: right;
    position: relative;
    top: -13px;
  }
  .people-reult-name {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
  }
  span.people-result-badge-age {
    padding: 11px 24px 11px 25px;
    margin-left: 0px;
    margin-top: -3px;
  }

  span.people-result-badge-status {
    padding: 11px 24px 11px 25px;
  }

  span.people-result-badge-events {
    padding: 11px 24px 11px 25px;
  }

  .people-result-pic img {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 7px solid rgb(228, 49, 147);
    object-fit: fill;
  }
  .people-result-men-pic img {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 5px solid rgb(43, 187, 211);
    object-fit: fill;
  }
  .people-result-men-pic {
    position: relative;
    right: 37px;
    bottom: 13px;
  }
  .people-result-pic {
    position: relative;
    right: 37px;
    bottom: 13px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  span.people-result-badge-age {
    padding: 11px 24px 11px 25px;
    margin-left: 0px;
    margin-top: -3px;
  }

  span.people-result-badge-status {
    padding: 11px 24px 11px 25px;
  }

  span.people-result-badge-events {
    padding: 11px 24px 11px 25px;
  }
}
